import styled from 'styled-components';

import theme from './theme';

export default styled.div`
  padding: 40px 0;

  h1 {
    font-size: 36px;
    margin: 0 0 10px;
    line-height: 1.2;
  }

  .post-meta {
    margin: 10px 0 25px;
    font-size: 14px;

    & > div {
      position: relative;
      display: inline-block;
      padding: 0 10px;

      &::before,
      &::after {
        content: '';
        position: absolute;
        display: block;
        height: 1px;
        width: 125px;
        background-color: ${theme.colors.lightGrey};
        top: 8px;
      }

      &::before {
        right: 100%;
      }

      &::after {
        left: 100%;
      }
    }

    span {
      opacity: 0.7;
    }

    &-dot {
      margin: 0 10px;
      font-weight: 800;
    }
  }

  .featured-image {
    height: 500px;
    width: 100%;
    object-fit: cover;

    @media (max-width: 767px) {
      height: auto;
    }
  }

  .featured-image-credits {
    font-size: 13px;
    text-align: center;
    font-style: italic;
  }

  .entry-content {
    padding: 20px 0;

    p {
      font-size: 18px;
      line-height: 1.6;

      &:first-child:first-letter {
        font-size: 60px;
        float: left;
        font-weight: bold;
        font-size: 60px;
        line-height: 1;
        display: block;
        margin-right: 10px;
      }
    }
  }

  .author-box {
    margin: 0;

    & > div {
      &:first-child {
        background-color: ${theme.colors.lightGrey};
        padding: 20px 0;
        width: 230px;
        margin-right: 30px;

        img {
          width: 160px;
          height: 160px;
          object-fit: cover;
          border: 4px solid #fff;
          border-radius: 999px;
        }

        @media (max-width: 767px) {
          margin-bottom: 25px;
          width: 100%;
        }
      }

      &:last-child {
        span {
          display: block;

          &:first-child {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 5px;
          }

          &:last-child {
            opacity: 0.7;
          }
        }
      }
    }
  }
`;
