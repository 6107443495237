import showdown from 'showdown';

export function markdownToHTML(html) {
  if (html) {
    const converter = new showdown.Converter();

    return converter.makeHtml(html);
  }

  return null;
}
