/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import RehypeReact from 'rehype-react';

import Layout from 'components/Layout';
import Container from 'components/Container';
// import Row from 'components/Row';
import Image from 'components/Image';

import Content from 'styles/blog-post';

import { markdownToHTML } from 'utils';

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    img: Image,
  },
}).Compiler;

const BlogPostTemplate = ({ data, location, pageContext }) => {
  const post = data.markdownRemark;
  // const author = data.allMarkdownRemark.nodes[0].frontmatter;

  return (
    <Layout location={location}>
      <Helmet title={`${post.frontmatter.title} | Facticular`} />
      <Container isContent>
        <Content>
          <h1 className="center">{post.frontmatter.title}</h1>
          <div className="post-meta center">
            <div>
              <span className="uppercase blue">
                {post.frontmatter.categories}
              </span>
              {/* <Link
                to={`/categories/${post.frontmatter.categories}/`}
                className="uppercase">
                {post.frontmatter.categories}
              </Link> */}
              <span className="post-meta-dot">&#183;</span>
              <span>{post.frontmatter.date}</span>
            </div>
          </div>
          <Image
            className="featured-image"
            src={post.frontmatter.featured_image}
            alt={post.frontmatter.title}
            sizes="(max-width: 1200px) 100vw, 1200px"
          />
          {post.frontmatter.featured_image_caption && (
            <div
              className="featured-image-credits"
              dangerouslySetInnerHTML={{
                __html: markdownToHTML(post.frontmatter.featured_image_caption),
              }}
            />
          )}
          {/* <div
            className="entry-content"
            dangerouslySetInnerHTML={{ __html: post.html }}
          /> */}
          <div className="entry-content">{renderAst(pageContext.htmlAst)}</div>
          {/* <Row justify="normal" className="author-box" mobileColumn>
            <div className="center">
              <Image
                src={author.avatar}
                alt={author.name}
                sizes="(max-width: 200px) 100vw, 200px"
              />
            </div>
            <div>
              <span>{author.name}</span>
              <span>{author.bio}</span>
            </div>
          </Row> */}
        </Content>
      </Container>
    </Layout>
  );
};

BlogPostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featured_image
        featured_image_caption
        categories
        author
      }
    }
    # allMarkdownRemark(
    #   filter: {
    #     fileAbsolutePath: { regex: "/content/authors//" }
    #     frontmatter: { username: { eq: $author } }
    #   }
    # ) {
    #   nodes {
    #     frontmatter {
    #       name
    #       bio
    #       username
    #       avatar
    #     }
    #   }
    # }
  }
`;
